.rootText {
  padding: 20px;
  background: #fff;
}

.rootText b {
  margin: 20px 0;
  display: block;
}

.rootText a {
  padding: 0 5px;
}

.custom-class-1 {
  word-wrap: break-word;
}

.custom-class-2 {
  margin-bottom: 0in;
  text-align: center;
  line-height: normal;

}

.custom-class-3 {
  font-size: 14.0pt;

}

.custom-class-4 {
  margin-bottom: 0in;

  text-justify:
    inter-ideograph;
  line-height: normal;

}

.custom-class-5 {
  font-size: 14.0pt;
}

.custom-class-6 {
  font-size: 14.0pt;
}

.custom-class-7 {
  font-size: 8.0pt;

}

.custom-class-8 {
  margin-top: 5.0pt;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0in;

  text-justify: inter-ideograph;
  line-height:
    normal;

}

.custom-class-9 {
  font-size: 14.0pt;
}

.custom-class-10 {
  margin-top: 5.0pt;
  margin-right: 0in;
  margin-bottom: 5.0pt;
  margin-left: 0in;
  text-align: center;
  line-height: normal;

}

.custom-class-11 {
  margin-top: 5.0pt;
  margin-right: 0in;
  margin-bottom: 5.0pt;
  margin-left: 22.4pt;
  text-align: center;
  text-indent: -22.4pt;
  line-height: normal;

}

.custom-class-12 {
  font-size:
    14.0pt;

}

.custom-class-13 {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 22.4pt;
  text-align: center;
  text-indent: -22.4pt;
  line-height: normal;

}

.custom-class-14 {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 22.4pt;
  line-height: normal;

}

.custom-class-15 {
  margin-bottom: 0in;

  text-justify:
    inter-ideograph;
  line-height: normal;
  background: white;

}

.custom-class-16 {
  font-size: 10.0pt;
  font-family: Symbol;
  color: black
}

.custom-class-17 {
  font-size: 14.0pt;
  ;
  color: black
}

.custom-class-18 {
  font-size: 14.0pt;
  ;
  color: black
}

.custom-class-19 {
  font-size: 14.0pt;
  ;
  color: #222222
}

.custom-class-20 {
  margin-top: 5.0pt;
  margin-right: 0in;
  margin-bottom: 5.0pt;
  margin-left: 17.85pt;
  text-align: center;
  text-indent: -17.85pt;
  line-height: normal;

}

.custom-class-21 {
  font-size: 14.0pt;
}

.custom-class-22 {
  margin-top: 5.0pt;
  margin-right: 0in;
  margin-bottom: 5.0pt;
  margin-left: 35.7pt;
  text-align: center;
  text-indent: -17.85pt;
  line-height: normal;

}