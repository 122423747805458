.root {
  display: block;
}

.label {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--Light-Text-Secondary);
  display: block;
}

.input {
  width: 100%;
  height: 56px;
  border: 1px solid var(--Light-Field-Border);
  border-radius: 10px;
  padding: 12px 16px;
  color: var(--Light-Text-Primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
}

.errorInput {
  border: 1px solid var(--Light-Field-Error-Border);
  background: var(--Light-Field-Error-Background);
}

.input::placeholder {
  font-size: 16px;
  color: var(--Light-Text-Secondary);
  opacity: 1;
  font-weight: 400;
}

.input:disabled {
  opacity: 0.4;
  background: var(--Light-Background-Content-Tint);
}

.errorText {
  color: var(--Light-Accent-Accent);
  font-size: 13px;
  margin-top: 8px;
}