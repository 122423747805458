.root {
  display: block;
  position: relative;
}

.label {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--Light-Text-Secondary);
  display: block;
}

.input {
  font-size: 16px;
  width: 100%;
  height: 56px;
  border: 1px solid var(--Light-Field-Border);
  border-radius: 10px;
  padding: 12px 16px;
  color: var(--Light-Text-Primary);
  cursor: default;
}

.errorSelect {
  border: 1px solid var(--Light-Field-Error-Border);
  background: var(--Light-Field-Error-Background);
}

.input::placeholder {
  font-size: 16px;
  color: var(--Light-Text-Secondary);
  opacity: 1;
  font-weight: 400;
}

.input:focus {
  border: none;
}

.input:disabled {
  opacity: 0.4;
}

.errorText {
  color: var(--Light-Accent-Accent);
  font-size: 13px;
  margin-top: 8px;
}

.selectModalRoot {
  padding-bottom: 12px;
}

.header {
  position: relative;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
}

.close {
  position: absolute;
  right: 0;
  top: 14px;
  width: 24px;
  height: 24px;
  background-color: var(--Light-Button-Secondary-Background);
  border-radius: 50%;
  cursor: pointer;
}

.search {
  margin-bottom: 15px;
}

.elementList {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4099999964237213px;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

.elementList:hover {
  background-color: var(--Light-Button-Secondary-Background);
}

.elementListSelected {
  background-color: var(--Light-Button-Secondary-Background);
}

.rootBottomSheet div[data-rsbs-header="true"] {
  box-shadow: none;
}

.chevronIcon {
  position: absolute;
  color: var(--Light-Icon-Secondary);
  right: 17px;
  top: 40px;
}