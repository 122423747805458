.rootText {
  padding: 20px;
  background: #fff;
}

.rootText b {
  margin: 20px 0;
  display: block;
}

.rootText a {
  padding: 0 5px;
}


.class1 {
  word-wrap: break-word
}

.class2 {
  text-align: center
}

.class3 {
  margin-bottom: 0in;
  text-align: center;
  text-indent: -.25in;
  line-height: 13.5pt
}

.class4 {
  font-size: 14.0pt;
  color: black
}

.class5 {}

.class6 {
  margin-bottom: 0in;
  line-height: 13.5pt
}

.class7 {
  margin-top: 7.5pt;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0in;
  line-height: 13.5pt
}

.class8 {
  font-size: 14.0pt;
  color: black
}

.class9 {
  margin-bottom: 0in;
  text-align: center;
  line-height: 13.5pt
}

.class10 {
  font-size: 14.0pt;
  color: black
}

.class11 {
  margin-top: 7.5pt;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: .5in;
  text-indent: -.25in;
  line-height: 13.5pt
}

.class12 {
  font-size: 14.0pt;
  font-family: Symbol;
  color: black
}