.root {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  margin-top: 8px;
  padding-bottom: 5px;
}


.chip {
  height: 36px;
  border-radius: 10px;
  background-color: var(--Light-Button-Secondary-Background);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.15000000596046448px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  text-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}