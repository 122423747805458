.root {}

.rootSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.form {}

.header {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--Light-Background-Content);
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
}

.subTitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.07999999821186066px;
  text-align: center;
  color: var(--Light-Header-Text-Secondary);
  margin-top: 2px;
}

.fieldsBlock {
  padding: 16px;
  background-color: var(--Light-Background-Content);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  max-width: 520px;
  margin: 0 auto;
}

.footer {
  position: absolute;
  bottom: 0px;
  background-color: var(--Light-Background-Content);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  width: 100%;
  padding: 16px;
  max-width: 520px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.fieldBlock {
  margin-bottom: 16px;
}