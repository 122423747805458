.root {
  display: block;
  position: relative;
}

.label {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--Light-Text-Secondary);
  display: block;
}

.input {
  width: 100%;
  height: 36px;
  border: 0;
  border-radius: 10px;
  padding: 12px 16px 12px 30px;
  color: var(--Light-Text-Primary);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  background: var(--Light-Search-Bar-Background);

  text-align: left;
}

.errorInput {
  border: 1px solid var(--Light-Field-Error-Border);
  background: var(--Light-Field-Error-Background);
}

.input::placeholder {
  font-size: 16px;
  color: var(--Light-Text-Secondary);
  opacity: 1;
  font-weight: 400;
}

.input:disabled {
  opacity: 0.4;
}

.errorText {
  color: var(--Light-Accent-Accent);
  font-size: 13px;
  margin-top: 8px;
}

.searchIcon {
  position: absolute;
  color: var(--Light-Search-Bar-Tint);
  top: 10px;
  left: 9px;
}