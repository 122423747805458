.root {
  background-color: var(--Light-Background-Content);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  height: 100vh;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.3799999952316284px;
  text-align: center;
  color: var(--Light-Text-Primary);
  margin-top: 16px;
}

.subTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  color: var(--Light-Text-Secondary);
  margin-top: 8px;
}

.footer {
  position: absolute;
  bottom: 16px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.buttonLink {
  background: var(--Light-Button-Secondary-Background);
  border-radius: 16px;
  padding: 3px 20px;
  color: var(--Light-Button-Tertiary-Foreground);
  text-decoration: none;
  height: 48px;
  display: flex;
  align-items: center;
}