.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  background-color: var(--Light-Background-Content);
  border-radius: 24px;
  padding: 24px 16px;
  width: 520px;
}

.logo {
  margin-bottom: 32px;
}

.transactionNumber {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
}

.blockMainInfo {
  margin-bottom: 32px;
}

.transactionNumber {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;

}

.paymentAmount {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-family: "SF Pro Display";
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.33000001311302185px;
  margin-bottom: 8px;
}

.amount {
  padding-right: 5px;
}

.symbol {
  color: var(--Light-Text-Secondary);
}

.descriptions {
  font-family: "SF Pro Text";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.15000000596046448px;
  color: var(--Light-Text-Secondary);
}

.emailBlock {
  margin-bottom: 16px;
}

.politic {
  margin-top: 8px;
  color: var(--Light-Text-Secondary);
  font-family: "SF Pro Text";
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.07999999821186066px;
  text-align: center;
}

.politic a {
  color: var(--Light-Accent-Accent);
}

.paymentSystems {
  margin-top: 24px;
  text-align: center;
}


.stub {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .container {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

}

.informer {
  padding: 16px;
  background-color: #FAF3EB;
  border-radius: 16px;
  font-family: "SF Pro Text";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}