.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.icon {
  margin-bottom: 24px;
  margin-top: 6px;
}

.title {
  font-family: "SF Pro Display";
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.3799999952316284px;
  margin-bottom: 8px;
}

.description {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3199999928474426px;
}