@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/SFProText-Light.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SFProText-Regular.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/SFProText-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/SFProText-SemiBold.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/SFProText-Bold.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/SFProText-Heavy.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/SFProDisplay-Ultralight.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/SFProDisplay-Thin.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/SFProDisplay-Light.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/SFProDisplay-Regular.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/SFProDisplay-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/SFProDisplay-SemiBold.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/SFProDisplay-Bold.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/SFProDisplay-Black.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/SFProDisplay-Heavy.ttf");
}

body {
  margin: 0;
  font-family: 'SF Pro Text', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(230, 230, 230, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


:root {
  --Light-Button-Primary-Background: #AE0C33;
  --Light-Button-Primary-Foreground: #FFFFFF;
  --Light-Button-Secondary-Background: #F2F2F2;
  --Light-Button-Secondary-Foreground: #AE0C33;
  --Light-Button-Tertiary-Background: rgba(255, 255, 255, 1);

  --Light-Text-Primary: rgba(0, 0, 0, 1);
  --Light-Text-Reverse: rgba(255, 255, 255, 1);
  --Light-Text-Secondary: rgba(139, 139, 139, 1);

  --Light-Field-Background: rgba(255, 255, 255, 1);
  --Light-Field-Border: rgba(0, 0, 0, 0.12);
  --Light-Field-Error-Background: rgba(253, 231, 236, 1);
  --Light-Button-Secondary-Foreground: rgba(174, 12, 51, 1);
  --Light-Field-Success-Background: rgba(224, 245, 214, 1);
  --Light-Field-Success-Border: rgba(80, 164, 40, 1);

  --Light-Accent-Accent: rgba(174, 12, 51, 1);
  --Light-Field-Error-Border: rgba(174, 12, 51, 1);

  --Light-Search-Bar-Tint: rgba(139, 139, 139, 1);
  --Light-Search-Bar-Background: rgba(0, 0, 0, 0.04);
  --Light-Icon-Secondary: rgba(139, 139, 139, 1);

  --Light-Header-Text-Secondary: rgba(139, 139, 139, 1);
  --Light-Background-Content: rgba(255, 255, 255, 1);
  --Light-Button-Tertiary-Foreground: rgba(174, 12, 51, 1);
  --Light-Background-Content-Tint: #E6E6E6;

}